'use strict';

var glify = undefined;

module.exports = {
    "debug": {"vertex":"precision mediump float;attribute vec2 a_pos;uniform float u_pointsize;uniform mat4 u_matrix;void main(){gl_Position=u_matrix*vec4(a_pos,step(32767.,a_pos.x),1);gl_PointSize=u_pointsize;}","fragment":"precision mediump float;uniform vec4 u_color;void main(){gl_FragColor=u_color;}"},
    "dot": {"vertex":"precision mediump float;uniform mat4 u_matrix;uniform float u_size;attribute vec2 a_pos;void main(){gl_Position=u_matrix*vec4(a_pos,0,1);gl_PointSize=u_size;}","fragment":"precision mediump float;uniform vec4 u_color;uniform float u_blur;void main(){float a,b;a=length(gl_PointCoord-.5);b=smoothstep(.5,.5-u_blur,a);gl_FragColor=u_color*b;}"},
    "fill": {"vertex":"precision mediump float;attribute vec2 a_pos;uniform mat4 u_matrix;void main(){gl_Position=u_matrix*vec4(a_pos,0,1);gl_PointSize=2.;}","fragment":"precision mediump float;uniform vec4 u_color;void main(){gl_FragColor=u_color;}"},
    "circle": {"vertex":"precision mediump float;uniform float u_size;attribute vec2 a_pos;uniform mat4 u_matrix,u_exmatrix;varying vec2 a;void main(){a=vec2(mod(a_pos,2.)*2.-1.);vec4 b=u_exmatrix*vec4(a*u_size,0,0);gl_Position=u_matrix*vec4(a_pos*.5,0,1);gl_Position+=b*gl_Position.w;}","fragment":"precision mediump float;uniform vec4 u_color;uniform float u_blur,u_size;varying vec2 a;void main(){float b=smoothstep(1.-u_blur,1.,length(a));gl_FragColor=u_color*(1.-b);}"},
    "line": {"vertex":"precision mediump float;attribute vec2 a_pos;attribute vec4 a_data,a_color;uniform highp mat4 u_matrix;uniform float u_ratio,u_extra;uniform vec2 u_linewidth;uniform mat2 u_antialiasingmatrix;varying vec2 a;varying float b,d;varying vec4 c;void main(){vec2 e,f;e=a_data.xy;f=mod(a_pos,2.);f.y=sign(f.y-.5);a=f;vec4 g=vec4(u_linewidth.s*e*.015873016,0,0);gl_Position=u_matrix*vec4(floor(a_pos*.5)+g.xy/u_ratio,0,1);float h,i,j;h=gl_Position.y/gl_Position.w;i=length(e)/length(u_antialiasingmatrix*e);j=1./(1.-h*u_extra);d=j*i;c=a_color;}","fragment":"precision mediump float;uniform vec2 u_linewidth,u_dasharray;uniform float u_blur;varying vec4 c;varying vec2 a;varying float b,d;void main(){float e,f,g;e=length(a)*u_linewidth.s;f=u_blur*d;g=clamp(min(e-(u_linewidth.t-f),u_linewidth.s-e)/f,0.,1.);gl_FragColor=c*g;}"},
    "linepattern": {"vertex":"precision mediump float;attribute vec2 a_pos;attribute vec4 a_data;uniform highp mat4 u_matrix;uniform mat4 u_exmatrix;uniform float u_ratio;uniform vec2 u_linewidth;varying vec2 a;varying float b;void main(){vec2 c,e,f,g;c=a_data.xy;float d=a_data.z*128.+a_data.w;e=mod(a_pos,2.);e.y=sign(e.y-.5);a=e;f=c*.015873016;g=u_linewidth.s*f;gl_Position=u_matrix*vec4(floor(a_pos*.5)+g.xy/u_ratio,0,1);b=d;}","fragment":"precision mediump float;uniform vec2 u_linewidth,u_pattern_size_a,u_pattern_size_b,u_pattern_tl_a,u_pattern_br_a,u_pattern_tl_b,u_pattern_br_b;uniform float u_point,u_blur,u_fade,u_opacity;uniform sampler2D u_image;varying vec2 a;varying float b;void main(){float c,d,e,f,g,h;c=length(a)*u_linewidth.s;d=clamp(min(c-(u_linewidth.t-u_blur),u_linewidth.s-c)/u_blur,0.,1.);e=mod(b/u_pattern_size_a.x,1.);f=mod(b/u_pattern_size_b.x,1.);g=.5+a.y*u_linewidth.s/u_pattern_size_a.y;h=.5+a.y*u_linewidth.s/u_pattern_size_b.y;vec2 i,j;i=mix(u_pattern_tl_a,u_pattern_br_a,vec2(e,g));j=mix(u_pattern_tl_b,u_pattern_br_b,vec2(f,h));vec4 k=mix(texture2D(u_image,i),texture2D(u_image,j),u_fade);d*=u_opacity;gl_FragColor=k*d;}"},
    "linesdfpattern": {"vertex":"precision mediump float;attribute vec2 a_pos;attribute vec4 a_data,a_color;uniform highp mat4 u_matrix;uniform mat4 u_exmatrix;uniform vec2 u_linewidth,u_patternscale_a,u_patternscale_b;uniform float u_ratio,u_tex_y_a,u_tex_y_b;varying vec2 a,b,c;varying vec4 d;void main(){vec2 e,g;e=a_data.xy;float f=a_data.z*128.+a_data.w;g=mod(a_pos,2.);g.y=sign(g.y-.5);a=g;vec4 h=vec4(u_linewidth.s*e*.015873016,0,0);gl_Position=u_matrix*vec4(floor(a_pos*.5)+h.xy/u_ratio,0,1);b=vec2(f*u_patternscale_a.x,g.y*u_patternscale_a.y+u_tex_y_a);c=vec2(f*u_patternscale_b.x,g.y*u_patternscale_b.y+u_tex_y_b);d=a_color;}","fragment":"precision mediump float;uniform vec2 u_linewidth;uniform float u_blur,u_sdfgamma,u_mix;uniform sampler2D u_image;varying vec2 a,b,c;varying vec4 d;void main(){float e,f,g,h,i;e=length(a)*u_linewidth.s;f=clamp(min(e-(u_linewidth.t-u_blur),u_linewidth.s-e)/u_blur,0.,1.);g=texture2D(u_image,b).a;h=texture2D(u_image,c).a;i=mix(g,h,u_mix);f*=smoothstep(.5-u_sdfgamma,.5+u_sdfgamma,i);gl_FragColor=d*f;}"},
    "outline": {"vertex":"precision mediump float;attribute vec2 a_pos;uniform highp mat4 u_matrix;uniform vec2 u_world;varying vec2 a;void main(){gl_Position=u_matrix*vec4(a_pos,0,1);a=(gl_Position.xy/gl_Position.w+1.)/2.*u_world;}","fragment":"precision mediump float;uniform vec4 u_color;varying vec2 a;void main(){float b,c;b=length(a-gl_FragCoord.xy);c=smoothstep(1.,0.,b);gl_FragColor=u_color*c;}"},
    "pattern": {"vertex":"precision mediump float;uniform mat4 u_matrix;uniform mat3 u_patternmatrix_a,u_patternmatrix_b;attribute vec2 a_pos;varying vec2 a,b;void main(){gl_Position=u_matrix*vec4(a_pos,0,1);a=(u_patternmatrix_a*vec3(a_pos,1)).xy;b=(u_patternmatrix_b*vec3(a_pos,1)).xy;}","fragment":"precision mediump float;uniform float u_opacity,u_mix;uniform vec2 u_pattern_tl_a,u_pattern_br_a,u_pattern_tl_b,u_pattern_br_b;uniform sampler2D u_image;varying vec2 a,b;void main(){vec2 c,d,f,g;c=mod(a,1.);d=mix(u_pattern_tl_a,u_pattern_br_a,c);vec4 e,h;e=texture2D(u_image,d);f=mod(b,1.);g=mix(u_pattern_tl_b,u_pattern_br_b,f);h=texture2D(u_image,g);gl_FragColor=mix(e,h,u_mix)*u_opacity;}"},
    "raster": {"vertex":"precision mediump float;uniform mat4 u_matrix;uniform vec2 u_tl_parent;uniform float u_scale_parent,u_buffer_scale;attribute vec2 a_pos,a_texture_pos;varying vec2 a,b;void main(){gl_Position=u_matrix*vec4(a_pos,0,1);a=(a_texture_pos/32767.-.5)/u_buffer_scale+.5;b=a*u_scale_parent+u_tl_parent;}","fragment":"precision mediump float;uniform float u_opacity0,u_opacity1,u_brightness_low,u_brightness_high,u_saturation_factor,u_contrast_factor;uniform sampler2D u_image0,u_image1;varying vec2 a,b;uniform vec3 u_spin_weights;void main(){vec4 c,d,e;c=texture2D(u_image0,a);d=texture2D(u_image1,b);e=c*u_opacity0+d*u_opacity1;vec3 f,h,i;f=e.rgb;f=vec3(dot(f,u_spin_weights.xyz),dot(f,u_spin_weights.zxy),dot(f,u_spin_weights.yzx));float g=(e.r+e.g+e.b)/3.;f+=(g-f)*u_saturation_factor;f=(f-.5)*u_contrast_factor+.5;h=vec3(u_brightness_low);i=vec3(u_brightness_high);gl_FragColor=vec4(mix(h,i,f),e.a);}"},
    "icon": {"vertex":"precision mediump float;attribute vec2 a_pos,a_offset;attribute vec4 a_data1,a_data2;uniform highp mat4 u_matrix;uniform mat4 u_exmatrix;uniform float u_zoom,u_fadedist,u_minfadezoom,u_maxfadezoom,u_fadezoom,u_opacity,u_extra;uniform bool u_skewed;uniform vec2 u_texsize;varying vec2 a;varying float b;void main(){vec2 c,e;c=a_data1.xy;float d,f,g,h,i,j;d=a_data1[2];e=a_data2.st;f=e[0];g=e[1];h=10.;i=2.-step(f,u_zoom)-(1.-step(g,u_zoom));j=clamp((u_fadezoom-d)/u_fadedist,0.,1.);if(u_fadedist>=0.)b=j;else b=1.-j;if(u_maxfadezoom<d)b=0.;if(u_minfadezoom>=d)b=1.;i+=step(b,0.);if(u_skewed){vec4 k=u_exmatrix*vec4(a_offset/64.,0,0);gl_Position=u_matrix*vec4(a_pos+k.xy,0,1);gl_Position.z+=i*gl_Position.w;}else{vec4 k=u_exmatrix*vec4(a_offset/64.,i,0);gl_Position=u_matrix*vec4(a_pos,0,1)+k;}a=c/u_texsize;b*=u_opacity;}","fragment":"precision mediump float;uniform sampler2D u_texture;varying vec2 a;varying float b;void main(){gl_FragColor=texture2D(u_texture,a)*b;}"},
    "sdf": {"vertex":"precision mediump float;attribute vec2 a_pos,a_offset;attribute vec4 a_data1,a_data2;uniform highp mat4 u_matrix;uniform mat4 u_exmatrix;uniform float u_zoom,u_fadedist,u_minfadezoom,u_maxfadezoom,u_fadezoom,u_extra;uniform bool u_skewed;uniform vec2 u_texsize;varying vec2 a;varying float b,c;void main(){vec2 d,f;d=a_data1.xy;float e,g,h,i,j,k,l;e=a_data1[2];f=a_data2.st;g=f[0];h=f[1];i=2.-step(g,u_zoom)-(1.-step(h,u_zoom));j=clamp((u_fadezoom-e)/u_fadedist,0.,1.);if(u_fadedist>=0.)b=j;else b=1.-j;if(u_maxfadezoom<e)b=0.;if(u_minfadezoom>=e)b=1.;i+=step(b,0.);if(u_skewed){vec4 k=u_exmatrix*vec4(a_offset/64.,0,0);gl_Position=u_matrix*vec4(a_pos+k.xy,0,1);gl_Position.z+=i*gl_Position.w;}else{vec4 k=u_exmatrix*vec4(a_offset/64.,i,0);gl_Position=u_matrix*vec4(a_pos,0,1)+k;}k=gl_Position.y/gl_Position.w;l=1./(1.-k*u_extra);c=l;a=d/u_texsize;}","fragment":"precision mediump float;uniform sampler2D u_texture;uniform vec4 u_color;uniform float u_buffer,u_gamma;varying vec2 a;varying float b,c;void main(){float d,e,f;d=u_gamma*c;e=texture2D(u_texture,a).a;f=smoothstep(u_buffer-d,u_buffer+d,e)*b;gl_FragColor=u_color*f;}"},
    "collisionbox": {"vertex":"precision mediump float;attribute vec2 a_pos,a_extrude,a_data;uniform mat4 u_matrix;uniform float u_scale;varying float a,b;void main(){gl_Position=u_matrix*vec4(a_pos+a_extrude/u_scale,0,1);a=a_data.x;b=a_data.y;}","fragment":"precision mediump float;uniform float u_zoom,u_maxzoom;varying float a,b;void main(){float c=.5;gl_FragColor=vec4(0,1,0,1)*c;if(b>u_zoom)gl_FragColor=vec4(1,0,0,1)*c;if(u_zoom>=a)gl_FragColor=vec4(0,0,0,1)*c*.25;if(b>=u_maxzoom)gl_FragColor=vec4(0,0,1,1)*c*.2;}"}
};
